import _constants from "./constants";
import _util from "./util";
var exports = {};
const constants = _constants;
const {
  encodeText,
  decodeText,
  concat
} = _util;
/** @typedef {import('./base')} Base */

/** @typedef {import("./types").BaseNameOrCode} BaseNameOrCode */

/** @typedef {import("./types").BaseCode} BaseCode */

/** @typedef {import("./types").BaseName} BaseName */

/**
 * Create a new Uint8Array with the multibase varint+code.
 *
 * @param {BaseNameOrCode} nameOrCode - The multibase name or code number.
 * @param {Uint8Array} buf - The data to be prefixed with multibase.
 * @returns {Uint8Array}
 * @throws {Error} Will throw if the encoding is not supported
 */

function multibase(nameOrCode, buf) {
  if (!buf) {
    throw new Error("requires an encoded Uint8Array");
  }

  const {
    name,
    codeBuf
  } = encoding(nameOrCode);
  validEncode(name, buf);
  return concat([codeBuf, buf], codeBuf.length + buf.length);
}
/**
 * Encode data with the specified base and add the multibase prefix.
 *
 * @param {BaseNameOrCode} nameOrCode - The multibase name or code number.
 * @param {Uint8Array} buf - The data to be encoded.
 * @returns {Uint8Array}
 * @throws {Error} Will throw if the encoding is not supported
 *
 */


function encode(nameOrCode, buf) {
  const enc = encoding(nameOrCode);
  const data = encodeText(enc.encode(buf));
  return concat([enc.codeBuf, data], enc.codeBuf.length + data.length);
}
/**
 * Takes a Uint8Array or string encoded with multibase header, decodes it and
 * returns the decoded buffer
 *
 * @param {Uint8Array|string} data
 * @returns {Uint8Array}
 * @throws {Error} Will throw if the encoding is not supported
 *
 */


function decode(data) {
  if (data instanceof Uint8Array) {
    data = decodeText(data);
  }

  const prefix = data[0]; // Make all encodings case-insensitive except the ones that include upper and lower chars in the alphabet

  if (["f", "F", "v", "V", "t", "T", "b", "B", "c", "C", "h", "k", "K"].includes(prefix)) {
    data = data.toLowerCase();
  }

  const enc = encoding(
  /** @type {BaseCode} */
  data[0]);
  return enc.decode(data.substring(1));
}
/**
 * Is the given data multibase encoded?
 *
 * @param {Uint8Array|string} data
 */


function isEncoded(data) {
  if (data instanceof Uint8Array) {
    data = decodeText(data);
  } // Ensure bufOrString is a string


  if (Object.prototype.toString.call(data) !== "[object String]") {
    return false;
  }

  try {
    const enc = encoding(
    /** @type {BaseCode} */
    data[0]);
    return enc.name;
  } catch (err) {
    return false;
  }
}
/**
 * Validate encoded data
 *
 * @param {BaseNameOrCode} name
 * @param {Uint8Array} buf
 * @returns {void}
 * @throws {Error} Will throw if the encoding is not supported
 */


function validEncode(name, buf) {
  const enc = encoding(name);
  enc.decode(decodeText(buf));
}
/**
 * Get the encoding by name or code
 *
 * @param {BaseNameOrCode} nameOrCode
 * @returns {Base}
 * @throws {Error} Will throw if the encoding is not supported
 */


function encoding(nameOrCode) {
  if (Object.prototype.hasOwnProperty.call(constants.names,
  /** @type {BaseName} */
  nameOrCode)) {
    return constants.names[
    /** @type {BaseName} */
    nameOrCode];
  } else if (Object.prototype.hasOwnProperty.call(constants.codes,
  /** @type {BaseCode} */
  nameOrCode)) {
    return constants.codes[
    /** @type {BaseCode} */
    nameOrCode];
  } else {
    throw new Error(`Unsupported encoding: ${nameOrCode}`);
  }
}
/**
 * Get encoding from data
 *
 * @param {string|Uint8Array} data
 * @returns {Base}
 * @throws {Error} Will throw if the encoding is not supported
 */


function encodingFromData(data) {
  if (data instanceof Uint8Array) {
    data = decodeText(data);
  }

  return encoding(
  /** @type {BaseCode} */
  data[0]);
}

exports = exports = multibase;
exports.encode = encode;
exports.decode = decode;
exports.isEncoded = isEncoded;
exports.encoding = encoding;
exports.encodingFromData = encodingFromData;
const names = Object.freeze(constants.names);
const codes = Object.freeze(constants.codes);
exports.names = names;
exports.codes = codes;
export default exports;
const _encode = exports.encode,
      _decode = exports.decode,
      _isEncoded = exports.isEncoded,
      _encoding = exports.encoding,
      _encodingFromData = exports.encodingFromData,
      _names = exports.names,
      _codes = exports.codes;
export { _encode as encode, _decode as decode, _isEncoded as isEncoded, _encoding as encoding, _encodingFromData as encodingFromData, _names as names, _codes as codes };